import {Uploader} from 'plupload';

/**
 * 获取一个 len 位的随机字符串
 * @param len
 */
function getRandomString(len) {
  len = len || 32;
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  const maxPos = chars.length;
  let pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

export {getRandomString};

export default class OssUploader {
  browserElement = null; // 浏览器按钮元素，Uploader 需要，没有的话 init 不会执行
  containerId = ''; // container
  selectId = ''; // browser_button
  uploadId = ''; // upload_button

  uploader = null;

  constructor(callback) {
    this.createBrowserElement();
    this.createUploader(callback);
  }

  createUploader({init, finish, error}) {
    this.uploader = new Uploader({
      browse_button: this.selectId,
      container: this.browserElement,
      runtimes: 'html5,html4',
      multi_selection: false,
      multiple_queues: false,
      url: 'http://oss.aliyuncs.com',

      filters: {
        max_file_size: '1GB', // 最大只能上传10mb的文件
        prevent_duplicates: true // 不允许选取重复文件
      },
    });

    this.uploader.bind('PostInit', (up) => {
      this.log(`[PlUploader] PostInit`);
      init(up);
    });

    this.uploader.bind('BeforeUpload', (up, file) => {
      this.log(`[PlUploader] Before Upload `, file);
    });

    this.uploader.bind('FilesAdded', (up, files) => {
      this.log(`[PlUploader] files added `, files);
    });

    this.uploader.bind('UploadProgress', (up, file) => {
      this.log(`[PlUploader] process: ${file.id} - ${file.percent}%`);
    });

    this.uploader.bind('UploadComplete', (up, files) => {
      this.log(`[UploadComplete] `, files);
    });

    this.uploader.bind('FileUploaded', (up, file, info) => {
      if (info.status === 200) {
        this.log('[FileUploaded] success', file, info);
      } else if (info.status === 203) {
        this.log('[FileUploaded] 上传到OSS成功，但是oss访问用户设置的上传回调服务器失败，失败原因是:' + info.response);
      } else {
        this.log(info.response);
      }

      finish(up, file, info);
    });

    this.uploader.bind('Error', (up, err) => {
      this.error(`[Uploader Error] 上传文件失败 `, err);
      if (err.code === -600) {
        this.log(`选择的文件太大了,可以根据应用情况，在upload.js 设置一下上传的最大大小`);
      } else if (err.code === -601) {
        this.log(`选择的文件后缀不对,可以根据应用情况，在upload.js进行设置可允许的上传文件类型`);
      } else if (err.code === -602) {
        this.log(`这个文件已经上传过一遍了`);
      } else {
        this.log(`Error xml: ${err.response}`);
      }

      error(up, err);
    });

    this.uploader.init();

    return this.uploader;
  }

  createBrowserElement() {
    this.containerId = 'container_' + getRandomString(8);
    this.selectId = 'select_' + getRandomString(8);
    this.uploadId = 'upload_' + getRandomString(8);

    const select = this.createElement('a', this.selectId);
    const upload = this.createElement('a', this.uploadId);
    this.browserElement = this.createElement('div', this.containerId);
    this.browserElement.appendChild(select);
    this.browserElement.appendChild(upload);
    document.body.appendChild(this.browserElement);
  }

  createElement(tag, id) {
    const ele = document.createElement(tag);
    ele.id = id;
    ele.style = 'display: none; width: 0; height: 0; overflow: hidden;';
    return ele;
  }

  log(logger, dir) {
    console.log(logger);
    dir && console.dir(dir);
  }

  error(errMsg) {
    console.error(errMsg);
  }
}


