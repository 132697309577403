<template>
  <a-pagination
    v-if="pagination && pagination.total"
    class="pagination"
    :current="pager.current"
    :default-current="pager.defaultCurrent"
    :default-page-size="pager.defaultPageSize"
    :page-size="pager.pageSize"
    :page-size-options="pager.pageSizeOptions"
    :show-size-changer="pager.showSizeChanger"
    :show-quick-jumper="pager.showQuickJumper"
    :show-total="pager.showTotal"
    :total="pager.total"
    @change="onPageChange"
    @showSizeChange="onSizeChange">
    <template slot="buildOptionText" slot-scope="props">
      <span>{{ props.value }}条/页</span>
    </template>
  </a-pagination>
</template>

<script>

function getPagination() {
  return {
    current: 1,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSize: 10,
    pageSizeOptions: ['10', '20', '30', '50', '100'],
    showQuickJumper: true,
    showSizeChanger: true,
    showTotal: (total) => `共 ${total} 条`,
    total: 0,
  };
}

function updatePagination(pager, pagination) {
  pager = pager || {};
  pagination = pagination || {};

  return Object.assign({}, pager, {
    total: pagination?.total || 0,
    current: pagination?.current_page || 1,
    pageSize: pagination?.per_page || pager.defaultPageSize,
  });
}

export { getPagination, updatePagination };

export default {
  name: 'Pagination',
  props: {
    pagination: {
      type: Object,
      default: () => {
        return getPagination();
      },
    },
  },
  computed: {
    pager() {
      const pag = getPagination();
      const {
        current = pag.current,
        defaultCurrent = pag.defaultCurrent,
        defaultPageSize = pag.defaultPageSize,
        pageSize = pag.pageSize,
        pageSizeOptions = pag.pageSizeOptions,
        showQuickJumper = pag.showQuickJumper,
        showSizeChanger = pag.showSizeChanger,
        showTotal = pag.showTotal,
        total = pag.total,
      } = this.pagination;
      return {
        current,
        defaultCurrent,
        defaultPageSize,
        pageSize,
        pageSizeOptions,
        showQuickJumper,
        showSizeChanger,
        showTotal,
        total,
      };
    },
  },
  methods: {
    onPageChange(page, pageSize) {
      this.$emit('change', page, pageSize);
    },
    onSizeChange(current, size) {
      this.$emit('showSizeChange', current, size);
    },
  },
};
</script>

<style scoped lang="scss">
.pagination {
  display: inline-flex;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  z-index: 10;
}
</style>
